.login-container { 
  & { max-width: 670px; height: 450px; width: 100%; background-color: #FFFFFF; display: flex;flex-direction: column; align-items: center; justify-content: space-around; text-align: center; margin-top: 65px; border-radius: 10px; }
  .login-inputs { 
    & { display: flex; flex-direction: column; gap: 16px; width: 50%; }
    .email-input { 
      & { display: flex; position: relative; }
      p { position: absolute; left: -70px; }
      input { width: 100%; height: 55px; border-radius: 3px; border: 1px solid #B1B1B1; background-color: #F6F6F6; font-size: 18px; font-weight: 300; line-height: 27px; padding: 0 8px; }
      input::placeholder{ font-size: 18px; font-weight: 300; line-height: 27px; color: #868686 }
    }
    .password-input {
      & { display: flex; position: relative; }
      p { position: absolute; left: -80px; }
      input { width: 100%; height: 55px; border-radius: 3px; border: 1px solid #B1B1B1; background-color: #F6F6F6; font-size: 18px; font-weight: 300; line-height: 27px; padding: 0 8px; }
      input::placeholder { font-size: 18px; font-weight: 300; line-height: 27px; }
    }
    button { height: 55px; border-radius: 3px; font-size: 18px; font-weight: 700; line-height: 27px; outline: none; border: none; text-transform: uppercase; }
    button:disabled { background-color: #A1A1A1; color: #ffffff; cursor: auto; }
    .active { background-color: #84161D; color: #FFFFFF; cursor: pointer ;}
  }
  .note {
    & { text-align: center; }
    span { color: #84161D; text-decoration: underline; }
  }
}

@media only screen and (max-width: 550px) {
  .login-container { width: 95%; margin: 55px auto; height: 100%; padding: 30px 0; }
  .login-container h1 { font-size: 24px; font-weight: 700;line-height: 30px; margin: 0; }
  .login-container .login-inputs { width: 70%; align-items: flex-start; }
  .login-container .login-inputs .email-input p { position: relative; left: -100px; }
  .login-container .login-inputs .password-input p { position: relative; left: -100px; }
  .login-container .login-inputs .email-input { flex-direction: column; width: 94%; }
  .login-container .login-inputs .password-input { flex-direction: column; width: 94%; }
  .login-container .login-inputs .email-input input { width: 100%; }
  .login-container .login-inputs .password-input input { width: 100%; }
  
.login-container .login-inputs button { width: 100%; }
}