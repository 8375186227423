// animations

@keyframes shake {
  0% { transform: translateX(0); color: #343333; }
  10% { transform: translateX(-5px); color: crimson; }
  20% { transform: translateX(5px); color: crimson; }
  30% { transform: translateX(-5px); color: crimson; }
  40% { transform: translateX(5px); color: crimson; }
  50% { transform: translateX(-5px); color: crimson; }
  60% { transform: translateX(5px); color: crimson; }
  70% { transform: translateX(-5px); color: crimson; }
  80% { transform: translateX(2.5px); color: crimson; }
  90% { transform: translateX(-2.5px); color: crimson; }
  100% { transform: translateX(0); color: #343333; }
}

.shake-text {
  animation: shake 0.5s ease-in-out;
}

.start {
  & { min-height: 756px; background: #ffffff; display: flex; justify-content: center; }
  .video {
    // & { padding: 0.5rem; border-radius: 8px 0 0 8px; position: relative; width: auto; height: 100%; display: flex; justify-content: center; object-fit: cover; transition: all 0.25s; overflow: hidden; }
    // video { margin: 0 auto; }
    & { width: 50%; height: auto; display: none; justify-content: center; position: relative; border-radius: 10px 0 0 10px; background-color: #00252A; object-fit: cover; transition: all 0.25s; overflow: hidden; }
    video { margin: 0px auto; position: relative; width: 100%; height: auto; }
    .thumbnail { position: absolute; width: 100%; height: auto; max-height: 560px; overflow: hidden; }
    .playbtn { position: absolute; /* top: 85%; */ top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 90px; width: auto; border-radius: 0; }
    // .playbtn { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 144px; width: 144px; }
    .custom-controls {
      & { position: absolute; box-sizing: border-box; bottom: 10px; max-height: 45px; width: 100%; max-width: 530px; left: 50%; top: 87.5%; transform: translate(-50%, -50%); background-color: rgba(0, 0, 0, 0.5); padding: 5px; display: flex; align-items: center; justify-content: space-between; gap: 10px; z-index: 2; border-radius: 30px; }
      button { background-color: transparent; border: none; color: white; cursor: pointer; align-self: center; padding: 5px; display: flex; justify-content: center; align-items: center; }
      input[type='range'] { width: 85%; height: 2px; -webkit-appearance: none; }
      input[type=range]::-webkit-slider-runnable-track { border-radius: 30px; }
      input[type=range]:hover::-webkit-slider-runnable-track { background-color: #fff; cursor: pointer; opacity: 0.95; }
      input[type='range']::-webkit-slider-thumb { -webkit-appearance: none; background-color: $color1; border: none; width: 10px; height: 10px; border-radius: 50%; }
      span { color: white; font-size: 14px; min-width: 8ch; min-width: 70px; text-align: left; }

    }
  }
  .intro {
    & { box-sizing: border-box; width: 90% !important; padding: 5% 2%; display: flex; flex-direction: column; justify-content: space-around; transition: all 0.25s; background-color: #FFFFFF; margin: 0 auto !important; }
    h1 { color: #84161D; text-align: center; font-family: $helvetica; font-size: 24px; font-weight: 700; margin-bottom: 20px; line-height: 36px; }
    .intro-para { color: #343333; text-align: center; font-family: $helvetica; font-size: 21px; font-weight: 300; line-height: 32px; max-width: 751px; width: 96%; margin: 0 auto 16px auto; }
    h2 { color: #84161D; text-align: center; font-family: $primaryfont; font-size: 24px; font-weight: 700; margin: 40px auto 20px auto; line-height: 36px;  }
    // h2 { color: #343333; text-align: center; font-family: $primaryfont; font-size: 36px; font-weight: 700; line-height: 150%; }
    .assessment-categories {
      & { display: flex; justify-content: space-between; max-width: 700px; width: 90%; margin: auto;gap:10px; }
      .category {
        & { width: calc(100% / 3 - (20px / 3)); max-width: 195px; cursor: pointer; aspect-ratio: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: #F6F6F6; border-radius: 10px; gap: 10px; }
        img { width: 100%;height: 100%; }
        p { font-weight: 700; margin: 0; font-size: 21px; line-height: 32px; color: #343333; font-family: $helvetica; }
      }
      .complete-icon { width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; color: #1b608a; }
      .img-container {height: 105px; }
    }
    .intro-points-list {
      & { list-style: none; padding-left: 0px; margin: 20px auto 20px 0; font-weight: 300; font-style: normal; color: #343333; font-size: 24px; line-height: 36px; max-width: 100%; }
      li { margin: 0px auto; }
    }
    .completion { 
      & { width: 100%; gap: 20px; display: flex; justify-content: center; flex-direction: column; align-items: center; text-align: center; margin: 30px auto 0; color: #343333; font-weight: 300; font-size: 21px; line-height: 21px; }
      .intro-para {margin-bottom: 0;}
      .cta { 
        & { padding: 12px 25px; margin-left: 0; /* transition: 0.3s; */ border-radius: 35px; background-color: #84161D; text-align: center; border: none; cursor: pointer; font-size: 24px; line-height: 36px; }
        .button-progress {padding-top: 5px; line-height: 31px; }
      }
      .cta:hover { background-color: #97A97C; transition: 0.3s; }
      .time-estimate {
        & { display: flex; align-items: center; justify-content: center; font-family: $helvetica; }
        .clock-icon { width: 20px; height: 20px; margin-top: 3px; padding-right: 3px; }
      }
    }
    .para-container{ 
      & {width: auto; text-align: center; padding-bottom: 15px; }
      .para { font-size: 14px; font-weight: normal; line-height: 24px; color: #343333; padding: 5px auto 30px; font-family: $primaryfont; }
    }
  }
    
  // .para-container {
  //   p { color: #5A5A5A; font-size: 24px; font-weight: 400; margin: 0.5rem; text-align: center; }
  // }
  //   .completion { & { display: flex; flex-direction: row; align-items: center; color: #4e4229; font-weight: 400; font-size: 18px; padding: 1.5rem 0rem; }
  //   }
  //   .btn-container{ & { padding-bottom: 2rem; }
  //   .cta { display: block; background: #348CFE; border-radius: 25px; color: #fff; text-decoration: none; width: 193px; text-align: center; font-family: $primaryfont; font-size: 20px; font-weight: 700; padding: 12px 0 12px; margin-left: 0; }
  //     .cta:hover { background: #fcb900; transition: 0.3s; color: #fff; }
  // }
  //     .time-estimate {
  //       & { display: flex; margin: 0; background-color: #FDE6A2; padding: 0.3rem 0.75rem 0.3rem 0.75rem; border-radius: 30px; }
  //       .clock-icon { width: 22px; height: 22px; }
  //       .img-container { padding-top: 0.1rem; padding-right: 0.25rem ; padding-left:0.5rem; }
  //     }
    
  }


.container.start {
  & { width: 95%; }
  .loading {
    & { background-color: transparent; }
  }
} 



@media only screen and (max-width: 830px) {
  
  .container.start { margin: 15px auto;  }
  .start {
    & { flex-direction: column; height: auto; width: 95%; }
    .video { & { width: 100%; box-sizing: border-box; height: auto; border-radius: 10px; }
      .custom-controls { max-width: 90%; height: 30px; top: 90%; }
      .playbtn { /* top: 82%; */ height: 60px; }
    }
    .intro { 
      & { background-color: #FFFFFF; border-radius: 10px; width: 100%; box-sizing: border-box; margin: 0 auto; padding: 15px; height: auto; }
      h1 { text-align: center; width: auto; font-size: 28px; margin: 0 auto 15px; max-width: 40ch; padding-top: 30px; }
      h2 { text-align: center; width: 100%; max-width: 34ch; }
      .intro-para { text-align: center; max-width: 55ch; margin: 5px auto; font-size: 21px; }
      .intro-points-list { width: auto; max-width: 54ch; margin: 15px auto; padding-left: 20px; }
      .completion { 
        & { flex-direction: column; margin: 30px auto 0 auto; }
        .time-estimate { margin: 15px auto; }
        .cta { margin-bottom: 0; }          
      }
      .assessment-categories .category {
        & { gap: 4px; }
        img {max-height: 80%; }
        .complete-icon { width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; color: #1b608a; }
        .img-container {max-height: 47px; display: flex; align-items: center; } 
      }
      
  }
  }
  .start .video video { width: 100%; } //margin: 0 -10%; object-fit: cover; }
  .start .video .thumbnail { width: 100%; height: auto; object-fit: cover; }

}

@media only screen and (max-width: 1000px) and (min-width: 831px) {
  
  .start .intro {
    h1 { font-size: 28px; line-height: 40px; }
    .intro-para { font-size: 22px; line-height: 30px; }
    .intro-points-list { font-size: 18px; }
    
    .completion { margin-top: 15px; }
    .completion .cta { font-size: 18px; max-width: 200px; }
  }

}

@media only screen and (min-width: 831px) {
  
}

@media only screen and (max-width: 1110px) {

}


@media only screen and (max-width: 650px) {
  
  .start { & {}
    .video .playbtn { height: 50px; /* top: 85%; */ }
    .intro {
      h1 { text-align: center; }
      h2 { text-align: center; font-size: 1.25rem; line-height: 25px; }
      // h1 br { content: ""; }
      h1 br:after { content: " "; }
    }
    .para-container p { font-size: 18px; }
  }

}

@media only screen and (max-width: 550px) {

  .start { margin-top: 15px; }
  .start .video { & { height: 57vw; width: 100%; border-radius: 10px; }
    video { width: auto; }
    .close-video { right: 5px !important; top: 5px !important; }
  }
  .start .intro { & {  }
    h1 { font-size: 21px; line-height: 25px; max-width: 26ch; margin: 0 auto 15px; font-weight: 700; padding-top: 5px; color: #84161D; }
    h2 { font-size: 21px; font-weight: 700; line-height: 27px; max-width: 25ch; color: #84161D; }
    .intro-para { font-size: 18px; line-height: 27px; max-width: 34ch; color: #000000; }
    .intro-points-list { font-size: 17px; font-weight: 300; line-height: 37px; max-width: 36ch; padding-left: 5px; }
    .completion img { margin: 0 4px 0 0; }
  }

  .start .intro .completion { & { margin: 30px auto 0; width: 100%; justify-content: center; }
    .time-estimate { margin-bottom: 0; }
  }
  .start .intro .completion .cta { margin: 0 auto; }
  .start .intro .assessment-categories {flex-direction: column; justify-content: center; align-items: center; gap: 15px;}
  .start .intro .assessment-categories .category {width: 100%; max-width: 270px; height: 100%; max-height: 47px; display: flex; flex-direction: row; align-items: center; justify-content: flex-start; padding-left: 20px; }
  .start .intro .assessment-categories .category p { margin-left: 1rem; }
  .start .intro .assessment-categories .category .category1 { margin-left: 2rem; }
  .start .intro .completion .time-estimate .clock-icon { margin: 0; padding-right: 4px; display: flex; align-items: center; justify-content: center; }
}
