

.assessment-additional.loading { background-color: transparent; }

.assessment-additional .step-buttons { max-width: 950px; justify-content: space-between; }

.assessment-additional {
  & { min-height: 575px; max-width: 850px; border-radius: 10px; color: $color3; width: 95%; position: relative; }
  .radio-container .slider-labels { & { font-size: 21px; }
    p { margin: 5px auto; }
  }
  .radio-container {
    .label { & { font-weight: 400; font-size: 21px; color: #343333; justify-content: center; align-items: center; }
      .Mui-checked { fill : $color2; color: $color2; }
    }
    .label.active { font-weight: 400; }
  }
  .steps {
    & { margin: 0 auto 15px; width: 80%; min-height: 400px; }
    .spacer { height: 50px; }
    h1 { font-size: 21px; font-weight: 700; text-align: center; color: #343333; margin: 30px auto 15px; }

    .progress-indicator { margin: 25px auto 10px; width: 100%; text-align: center; font-family: $primaryfont; font-weight: 400; color: #343333; font-size: 18px; }

    .question { & { min-height: 110px !important; display: flex; justify-content: center; align-items: center; text-align: center; font-family: $primaryfont; font-weight: 400; font-size: 24px; color: #343333; max-width: 42ch; margin: 0 auto; padding: 0 0 15px; line-height: 31px; }
      p { color: #747474; margin: 5px auto; font-family: $fontPTSerif; font-size: 24px; font-weight: 700; line-height: 31px; letter-spacing: 0px; text-align: center; }
    }
    .textarea { min-height: 80px; }
    .textarea-field { max-width: 540px; width: 80%; margin: 18px auto; margin-bottom: 0; }
    .MuiInput-root { font-family: $primaryfont !important; font-size: 21px; border: 1px solid #979797; padding: 14px 18px; border-radius: 5px; }
    // .form-details-text { background-color: #fff; padding: 0 9px 22px; height: 90px; }
    .form-details-text { background-color: #fff; border-radius: 8px; }

    .slider-container {
      & { width: 100% !important; max-width: 752px; margin: 30px auto 120px; }
      .slider { width: 100%; position: relative; }
      .slider-points {
        & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #182036; position: relative; }
        .point {
          & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background-color: #B9B9B9; position: absolute; z-index: 9; }
        }
      }
      .slider-labels { & { width: 125%; font-weight: 400; font-size: 20px; color: #182036; }
        .label { & { cursor: pointer; width: 20%; float: left; position: relative; text-transform: capitalize; }
          p { /* display: block; */ position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; font-weight: 400; font-family: $primaryfont; font-size: 21px; line-height: 26px; }
        }
        .active.label p { font-weight: 700; color: #CBCB00; }
      }

      .custom-slider.MuiSlider-root { z-index: 1000; color: #CBCB00; width: calc(100% + 10px); margin-left: -5px; padding: 12.5px 0; position: absolute; top: 2px; }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 5px solid #CBCB00; position: absolute; top: 0; }

      .custom-slider .MuiSlider-thumb { background-color: #FFF; padding: 8px; border: 4px solid #CBCB00; top: 0; }
      .custom-slider .MuiSlider-thumb:before { background-color: #CBCB00; width: 4px; height: 4px; padding: 2px; }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 1); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #D8D8D8; height: 3px; }
      .custom-bar .MuiLinearProgress-bar { background-color: $color2; top: -2px; }

    }

    .slider-none-adjust { height: 35px; position: absolute; top: 2px; }
  }
  
  .step-buttons {
    & { display: flex; justify-content: space-between; margin: 15px auto 15px; padding-bottom: 15px; width: 85%; box-sizing: border-box; padding: 5px 5px 30px; }
    button { font-weight: 600; font-size: 18px; font-family: $primaryfont; padding: 12px 25px; }
    .next-btn { color:#fff; background-color: $color2; font-weight: 700; padding: 0; min-width: 89px; min-height: 50px; flex-shrink: 0; display: flex; justify-content: center; align-items: center; }
    .next-btn:hover { background-color: $color3; }
    .next-btn.mobile { color: #343333; background-color: #fff; }
    .next-btn.Mui-disabled { background-color: #D8D8D8; color: #979797; }
    .all-set-btn { color:#fff; background-color: $color2; border-radius: 5px; padding: 7px 60px; }
  }
}


@media only screen and (min-width: 830px) {

  main .assessment-additional .steps {
    h1 { margin: 15px auto 15px; }
    .progress-indicator { margin: 15px auto 10px; }
    .slider-container .slider-labels .label p { & { font-size: 16px; line-height: 21px; } }
    .radio-container .slider-labels .label p { & { font-size: 16px; text-align: left; margin: 5px auto 5px 0; } }
  }
  main .assessment-additional .steps .slider-container .slider-labels { font-size: 16px; }

}




@media only screen and (min-width: 650px) {

  // main .assessment-additional .steps .slider-container .slider-labels br { content: ""; }
  // main .assessment-additional .steps .slider-container .slider-labels br:after { content: " "; }

}


@media only screen and (max-width: 550px) {

  main .assessment-additional { & { margin-top: 15px; }
    .steps .textarea-field { width: 100%; }
    .steps .form-details-text { height: auto; min-height: 120px; }
    .steps .question { font-size: 18px; line-height: 24px; padding: 0 0 5px; }
    .steps .question p br { content: ""; }
    .steps .question p br:after { content: " "; }
    .steps .slider-container .slider-labels { font-size: 14px; }
    .steps .step-buttons { padding-bottom: 30px; }
    .step-buttons .all-set-btn { font-size: 18px; padding: 9px 15px; }
  }

  main .assessment-additional .steps { margin: 0 auto 15px; display: flex; flex-direction: column; justify-content: center; align-items: center; min-height: 500px; width: 100%; padding: 0 15px; box-sizing: border-box; }
  main .assessment-additional .step-buttons { margin: 10px auto 15px; padding-top: 15px; padding-bottom: 30px; padding: 15px 0 30px; width: 90%; max-width: 95%; }

  main .assessment-additional .steps .slider-container .slider-labels .label p { font-size: 12px; line-height: 16px; }

  main .assessment-additional .steps .textarea { min-height: 120px; }
  main .assessment-additional .steps .textarea-field { margin-bottom: -15px; }

  main .assessment-additional .steps .slider-container .slider-points .point{ height: 7px !important; width: 7px !important; margin-top: 0; } 
  main .assessment-additional .steps .slider-container { margin: 100px auto 0; width: 100%; }
  main .assessment-additional .step-buttons button{ min-width: 0; padding: 20px; }
  main .assessment-additional .step-buttons .next-btn.mobile { min-width: 0; padding: 20px; }

}



