  .progress-bar {
    & { display: flex; justify-content: space-between; margin: 0 auto; margin-top: 10px; width: 100%; }
    .bar-section { 
      .progress-percentage { text-align: right; font-weight: 700; font-size:18px; margin: 1rem 0 0.5rem 1rem; transition: all 0.4s linear; font-family: $fontLato; color: #84161D; line-height: 27px; }
      .progress-grid { 
        display: grid; grid-template-columns: repeat(3, 1fr); max-width: 42px; margin: 2.5rem auto;
        .grid-square { width: 10px; height: 10px; margin: 2px; } 
      }
      .bar { height: 6px; margin: 0 5px 10px; }
      .section-title { width: 100%; font-family: $primaryfont; font-size: 16px; font-weight: 400; line-height: 19px; letter-spacing: 0px; text-align: center;  max-width: 171px; margin: 0 auto; }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 3px; }
    .custom-bar .MuiLinearProgress-bar { background-color: #44546A; width: 102%; left: -2px; }
    .sections { & {}
      .section-name { font-size: 18px; margin:15px auto 5px; }
    }
    .si_ass_result{
      &{ height: 105px; }
    }
  }