
.parishioner-container {
    & { margin: 2rem auto; padding:20px; font-family: $helvetica; font-size: 21px; line-height: 32px; color: #343333;  text-align: center; width: 94%; box-sizing: border-box; border-radius: 20px; }
    h3 { font-family: $helvetica; font-size: 24px;  font-weight: 700;  line-height: 31px; text-align: center; color: #84161D; }
    img { width: 441px; height: 441px; }
    .parishioner-container-spacer { display: flex; justify-content: center; align-items: center; gap: 1rem; border-radius: 10px; }
    .card { 
       & { background:  #F7F4F4; width: 330px; padding: 20px 15px; height: 340px; }
       span { font-family: $helvetica; font-size: 17px; font-weight: 700; line-height: 31px; text-align:start; color:#84161D; display: block; }
       p { font-family: $helvetica; font-size: 17px; font-weight: 300; line-height: 31px; text-align: left; color: #000000; margin: 0; }
    }
   
}


@media only screen and (max-width: 850px) {
  main .parishioner-container { max-width: 95%; font-size: 18px; margin: 0px auto; }
  main .parishioner-container p { font-size: 18px; }
  main .parishioner-container .parishioner-container-spacer h3 { font-size: 21px; line-height: 28px; }
  main .parishioner-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }

  .parishioner-container .parishioner-container-spacer { flex-direction: column; }
  .parishioner-container img { height: 400px; width: 400px; }
}