

.container.pre-assessment { max-width: 1094px; width:95%; height: auto;  align-items: center; margin:33px auto; background-color: #fff; }
.pre-assessment { 
  & { color: $color2; max-width: 65ch; margin: 0px auto 15px; font-family: $primaryfont; display: flex; flex-direction: column; justify-content: center; }
  h1 { font-size: 24px; font-weight: 300; font-family:$helvetica; line-height: 35px; text-align: center; color: #000; width: 90%; max-width: 58ch; margin: 45px auto; }
  h2 { 
    & { font-weight: 400; text-align: center; }
    span { font-weight: 600; color: $color2; }
  }
  p { color: #696969; font-size: 18px; line-height: 25px; text-align: center; font-weight: 400; font-family: $fontPTSerif; }
  .pre-assessment-subheader { font-weight: 600; text-align: center; font-family: $primaryfont; font-size: 18px; }
  .explanation {
    & { display: flex; flex-direction: row; justify-content: center; align-items: center; font-family: $primaryfont; font-size: 18px; }
    p { font-weight: 500; display: block; }
    .marker { box-sizing: border-box; flex: 0 0 30px; height: 30px; width: 100%; border-radius: 50%; background-color: white; margin: 10px 20px; }
    .red { border: 5px solid #FF2121; }
    .yellow { border: 5px solid #FDBF04; }
    .green { border: 5px solid #73B76F; }
  }
  .cta-link { text-decoration: none; margin: 30px auto 15px; }
  // .cta { font-family: $primaryfont; color: #fff; display: block; margin: 45px auto; width: 100%; max-width: 280px; text-align: center; background-color: #3A6891; border-radius: 4px; font-weight: 700; font-size: 21px; letter-spacing: 1px; padding: 5px 15px; text-transform: capitalize; }
  // .cta:hover { background-color: #CBCB00; }
 
  .img-text-row {
    & { display: flex; flex-direction: row; justify-content: center; width: 100%; max-width: 1400px; box-sizing: border-box; flex-wrap: wrap; }
    .img-text-wrapper { 
      & { box-sizing: border-box; width: 19%; max-width: 280px; padding: 15px 10px; margin: 0 auto; min-height: 450px; display: flex; justify-content: space-between; flex-direction: column; background-color: #6183B400; box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25); }
      &:hover { background-color: #bfdcea;  transition: .5s; }
      .img-label { font-size: 24px; font-weight: 700; line-height: 35px; margin: 10px auto; }
      img { width: 275px; }
      .text { display: flex; flex-direction: column; justify-content: center; align-items: center; }
      .img { min-height: 380px; display: flex; justify-content: center; align-items: center; }
    }
  }
   .step-buttons{ 
    & { align-items: center; width: 70%; justify-content: flex-start; gap:210px; }
    .next-btn {
      & { text-decoration: none; width:194.4px; height: 50px; background:#84161D; color: #fff; line-height: normal; font-family: $helvetica; border-radius: 5px; font-weight: 700; font-size: 21px; padding: 6px 0; text-align: center; border-radius:25px; }
    }
    .next-btn:hover{background-color: #97A97C;}
  }

  .middle-section{
    & { display: flex; width:70%;  justify-content: space-between; margin-top: 40px; }
    .items { display: flex; flex-direction: column; align-items: center; }
    h1 { color: #343333; text-align: center; font-family: $helvetica; font-size: 21px;  font-style: normal; font-weight: 700;  line-height: 150%; margin:0px; margin-top: 5px; text-transform: uppercase; width: auto; }
    .image-container{ 
      & { width: 144px; height: 144px; display: flex; justify-content: center; align-items: center; margin-bottom: 10px;}
      img{height: 100%; width: 100%;}
    }
    .data-container {
      & { display: flex; flex-direction: column; align-items: center; justify-content: center; }
    }
    .question-section {
     & { display: flex; gap: 10px; margin-top: -5px; }
     p{ color: #343333; text-align: center; font-family: $helvetica;  font-size: 18px;  font-style: normal;  font-weight: 300;  line-height: 100%; }
    }
  }
}



@media screen and (max-width: 1100px) {
  .pre-assessment .img-text-row .img-text-wrapper img { width: 100%; }
}


@media screen and (max-width: 830px) {
  .pre-assessment .img-text-row .img-text-wrapper { width: 40%; margin: 15px auto; }
  .pre-assessment .img-text-row .img-text-wrapper img { min-width: 200px; }
  .pre-assessment .middle-section { width: 90%; gap: 10px; }
}


@media screen and (max-width: 650px) {
  .pre-assessment h1 { font-size: 24px; }
  .pre-assessment .img-text-row .img-text-wrapper { width: 85%; }
  .pre-assessment .img-text-row .img-text-wrapper img { min-width: 200px; }
  .pre-assessment .step-buttons{ gap: 0px; width: 80%; justify-content: space-between; }
  .pre-assessment .middle-section{ flex-direction: column; }
  .pre-assessment .middle-section .items { flex-direction: row; justify-content: space-around; }
  .pre-assessment .middle-section h1 { text-align: left; font-size: 18px; font-weight: 700; }
  .pre-assessment .middle-section .data-container { align-items: flex-start; }
  
}
