.downloads-container {
  & {  width: 100%; background: #FFFFFF; display: flex; }
  .downloads-right-container { 
    & { border-radius: 20px 0px 0px ; border-top-left-radius: 1rem; background-color: #F5F5F5; width: 100%; height: calc(100vh - 90px); overflow-y: scroll; }
    h1 { font-family: $helvetica; font-size: 18px;  font-weight: 300; line-height: 31px; text-align: left; color: #595959; margin: 20px auto; max-width: 92%; }
    .download-section {
      & { background: #FFFFFF;  width: 90%; min-height: 461px; height: auto; margin: 0px auto; border-radius: 20px; padding: 10px 20px; }
      h2 { font-family: $helvetica; font-size: 24px;  font-weight: 700; line-height: 32px;  color: #000000; }
      h3 { font-family: $helvetica;  font-size: 21px; font-weight: 700; line-height: 32px;  color: #000000; }
      .download-table  {
        & { width: 100%; ; border-collapse: collapse; }
        .images-container { 
          & { display: flex; gap: 20px; align-items: center; justify-content: flex-start; margin-left: 45px; }
          img { width: 76px; height: 32px; cursor: pointer; }
        }
        .header th {  text-align: start; font-family: $helvetica; font-size: 21px; font-weight: 700; line-height: 32px; color: #000000; padding: 5px; }
        .content  th { text-align: start; padding:10px 5px;  }
        .content th p {  font-family: $helvetica; font-size: 21px; font-weight: 300;line-height: 32px; padding: 0px; margin: 0px; }
        tbody tr:nth-child(odd) { background-color: #F3F3F3; }
        input { height: 20px; width: 20px; cursor: pointer; }
      }
    }
    .download-btn {
      & { display: flex; align-items: center; justify-content: center; margin: 30px auto; }
      #downloadBtn { width: max-content; max-width: 100%; min-width: 238px; height: 50px; border-radius: 25px; font-size: 21px; font-weight: 700; background-color: #84161D; color: #FFFFFF; border: none; cursor: pointer; }
    }
  }


}


button.btn-ok { width: max-content; display: flex; justify-content: center; max-width: 100%; min-width: 100px; height: 50px; border-radius: 10px; font-size: 21px; font-weight: 700; background-color: #84161D; color: #FFFFFF; border: none; cursor: pointer; margin: 20px auto 0px; }
button.btn-ok:hover { width: max-content; display: flex; justify-content: center; max-width: 100%; min-width: 100px; height: 50px; border-radius: 10px; font-size: 21px; font-weight: 700; background-color: #84161D; color: #FFFFFF; border: none; cursor: pointer; margin: 20px auto 0px; }