
.discover-container {
    & { margin: 2rem auto; padding:20px; font-family: $helvetica; font-size: 21px; line-height: 32px; color: #343333; background-color: #ffffff; text-align: center; width: 94%; box-sizing: border-box; border-radius: 20px; }
    .discover-container-spacer { 
      h3 { font-size: 30px; font-weight: 700; line-height: 43px; margin: 10px; color: #343333; font-family: $helvetica; }
    }
    p { font-weight: 300; font-size: 21px; line-height: 25.2px; color: #343333; font-family: $helvetica; margin: 0px auto; max-width: 75ch; }
    .cta-btn {
      & { display: inline-block; margin: 20px auto 0; color: #fff; padding: 0; border-radius: 5px; width: 100%; max-width: 280px; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: max-content; max-width: 100%; min-width: 238px; display:  inline-block;  background-color: #84161D; padding: 5px 25px; color: #fff; font-family: $helvetica; font-size: 21px; font-weight: 700; height: 50px; border-radius: 25px; border: none; cursor: pointer; }
      button:hover {  background-color: #97A97C; transition: 0.3s; color: #fff; }
    }
   
  }


@media only screen and (max-width: 550px) {
  main .discover-container {
    & { max-width: 95%; font-size: 18px; padding: 0; padding-top: 10px; }
    .cta-btn { display: none; }
    h3 { padding: 30px 0; }
    .mobile-experience-image {
      & { display: flex; }
      img { width: 100%; }
    }
  }
  main .discover-container p { font-size: 18px; }
  main .discover-container .discover-container-spacer h3 { font-size: 21px; line-height: 28px; }
  main .discover-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
}