.dashboard-compare { 
  & { width: 100%; background-color: #F5F5F5; height: calc(100vh - 90px); overflow-y: auto; }
  .sub-heading { font-weight: 300; line-height: 31px; font-size: 18px; margin-left: 3%; }
  .tabs { 
    & { max-width: 100%; width: 94%; display: flex; flex-direction: row; gap: 1rem; margin-left: 3%; }
    .tab { 
      & { cursor: pointer; background-color: #fff; border-radius: 10px; display: flex; justify-content: center; align-items: center; gap: 1rem; min-width: 232px; height: 80px; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; }
      img { object-fit: contain; height: 40px; }
      p { font-size: 24px; color: #3B3939; font-weight: 300; margin: 0; margin-bottom: 6px; }
      .squre-bar { 
        & { border: 1px solid #84161D; border-radius: 2px; height: 9px; width: 145px; display: flex; justify-content: space-between; align-items: center; padding-left: 1px; padding-right: 1px; }
        .squre { background-color: #84161D1A; border-radius: 2px; height: 7px; width: 12px; }
        .active-squre { background-color: #84161D; }
      }
    }
    .active { 
      & { height: 100px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0;  box-shadow: none;  }
      p { font-weight: 700; color: #84161D; transform: translateY(-10px); }
      img { transform: translateY(-10px); }
      .squre-bar { transform: translateY(-10px); }
     }
  }
  .box { 
    & { max-width: 100%; width: 94%; margin: -0.5rem auto; border-radius: 20px; background-color: #fff; padding-bottom: 1rem;}
    .le-pa-container { 
      // & { display: flex; align-items: center; gap: 3rem; padding: 0px 8px;  }
      .le-pa-container-row {
        & { display: flex; justify-content: flex-end; gap: 20px; padding: 10px 70px; }
      }
      .lp-container { 
        & { display: flex; align-items: center; justify-content: center;  }
        p { font-family: $helvetica; font-size: 21px; font-weight: 300; color: #3B3939;  display: inline-flex; }
        img { object-fit: contain; width: 33px; margin-right: 0.4rem; }
       }
     }
  }

  .top-compare-header {
    & { min-height: max-content; max-width: 85%; margin: 0px auto; background: #84161D; border-radius: 20px; padding: 15px; }

    .top-content {
      & { display: flex; justify-content: space-between; align-items: start; }
      img { height: 22px; }
      h3 { font-family: $helvetica; font-size: 18px;  font-weight: 700;  line-height: normal; color: #FFFFFF; margin: 0;  }
    }

    .user-type-container {
      & { display: flex; justify-content: space-between; align-items: center; margin-top: 20px; }
      .contents { display: flex; gap: 10px; }
      img { max-height: 31px; }
      span { font-family: $helvetica; font-size: 16px;  font-weight: 700; line-height: 17px; color: #FFFFFF;  }
      p {  font-family: $helvetica; font-size: 14px;  font-weight: 300;  line-height: 17px;  color: #FFFFFF; margin: 0; }
    }
  }
  .mobile-compare-score  {
    & { background: #FFFFFF; min-height: 120px; border-radius: 20px; width: 90%; margin: 0px auto; padding: 12px; margin-bottom: 1rem; }
    .contents {
     & { display: flex; align-items: center; justify-content: center; gap: 20px; }
     img { width: 22px; }
     p { font-family: $helvetica; font-size: 16px; font-weight: 300; line-height: 15px; color: #3B3939; }
     .squre-bar { 
      & { border: 1px solid #84161D; border-radius: 2px; height: 15px; width: 145px; display: flex; justify-content: space-between; align-items: center; padding-left: 1px; padding-right: 1px; }
      .squre { background-color: #84161D1A; border-radius: 2px; height: 13px; width: 12px; }
      .active-squre { background-color: #84161D; }
    }
 
    }
  }
}

@media only screen and (max-width: 600px) {
//  .cover {
//   .dashboard-compare { 
//     & { border-radius: 0; }
//     .sub-heading { font-size: 12px; }
//     .tabs {
//       & { gap: .5rem; }
//       .tab { 
//         & { padding-left: .4rem; padding-right: .4rem; gap: .3rem; min-width: 104px; height: 40px; }
//         img { height: 18px; }
//         p { font-size: 12px; }
//         .squre-bar { 
//           & { width: 80px; height: 5px; }
//           .squre { width: 7px; height: 4px; }
//         }
//       }
//       .active { height: 60px; }
//     }
//     .box {
//       .le-pa-container { 
//         & { gap: 0rem; margin-right: 5%; }
//         .lp-container {
//           p { font-size: 12px; line-height: 15px; text-wrap: wrap; width: 50%; text-align: center; }
//         }
//        }
//     }
//   }
//   }
  .dashboard-compare { 
    & { border-radius: 0; }
    .sub-heading { font-size: 12px; }
    .tabs {
      & { gap: .5rem; }
      .tab { 
        & { padding: 0; gap: .3rem; min-width: 104px; height: 40px; background: unset; box-shadow: none; }
        img { height: 18px; }
        p { font-size: 12px; margin: 0; }
        .squre-bar { 
          & { width: 80px; height: 5px; }
          .squre { width: 7px; height: 4px; }
        }
      }
      .active { height: 60px; background: #fff; }
    }
    .box {
      .le-pa-container { 
        // & {  margin: 0px auto; width: 90%; }
        .le-pa-container-row {
          & { display: flex; justify-content: flex-end; gap: 20px; padding: 0 30px; }
        }
        .lp-container {
          p { font-size: 12px; line-height: 15px; text-wrap: wrap; width: 50%; text-align: center; }
        }
      }
    }

  }
  .dashboard-compare .sub-heading { font-family: $helvetica; font-size: 14px; font-weight: 300; line-height: 18px; text-align: center; color: #595959;  max-width: 31ch; margin: 10px auto; }
  .dashboard-compare .box { padding: 20px 0; }
}