
.assessment {
  & { background-color: #FFFFFF; width: 96%; max-width:1110px !important;min-height:607px; margin-bottom: 0; }
  .steps {
    & { margin: 0 auto 0; width: 90%; }
    .spacer { height: 60px; }
    .progress-indicator { margin: 15px auto 0; width: 100%; font-size: 24px; font-weight: 900; line-height: 33px; letter-spacing: 0px; text-align: center; font-family: $fontLato; }
    .question { 
      & { display: flex; justify-content: center; align-items: flex-start; min-height: 120px; max-width: 751px; margin: 0 auto; color: #343333; text-align: center; font-family: $fontLato; font-size: 24px; font-style: normal; font-weight: 400; line-height: 31px; }
      p { margin: 17px auto 0px; font-family: $helvetica; color: #343333; font-size: 30px; line-height: 40px; font-weight: 300; }
    }
    .question-no-3{
      p{ max-width: 50ch !important; }
    }
    .question-no-4{
      p{ max-width: 32ch !important; }
    }
    .radio-container {
      & { width: 86%; margin: 0 auto; display: flex; align-items: center; min-height: 130px; }
      .slider-labels {
        & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; display: flex; }
        .label {
          & { cursor: pointer; width: 20%; float: left; /* position: relative; */ }
          p { display: block; /* position: absolute; margin-left: -50%; */ margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; }
          p.desktop { display: block; }
          p.mobile { display: none; }
        }
        .active { font-weight: 700; color: #ED1B24; }
      }
      .MuiRadio-root { margin: 5px auto; display: flex; width: 30px; height: 30px; }
    }
    .textarea-field { 
      & { max-width: 540px; width: 85%; margin: 0 auto 0; background: #FFFFFF; border: 1px solid #979797; border-radius: 5px; padding: 6px 12px; }
      textarea { font-family: $helvetica; font-size: 21px; }
    }
    .slider-container {
      & { width: 86%; margin: 90px auto; }
      .slider { width: 100%; position: relative; }
      .slider-points {
        & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .point {
          & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -4px; background: #979797; position: absolute; z-index: 9; }
        }
      }
      .slider-labels {
        & { width: 125%; font-weight: 400; font-size: 21px; color: #343333; }
        .label {
          & { cursor: pointer; width: 20%; float: left; position: relative; color: #747474; }
          p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; z-index: 1000; text-align: center; font-family: $fontLato; font-size: 18px; font-style: normal; font-weight: 700; line-height: normal; }
          p.desktop { display: block; }
          p.mobile { display: none; }
        }
        .active { font-weight: 700; color:#1B608A; }
      }

      .custom-slider.MuiSlider-root { z-index: 1000; color: #1B608A; width: calc(100% + 10px); margin-left: -5px; padding: 12.5px 0; position: absolute; top: 2px; }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 5px solid #1B608A; position: absolute; top: 0; }

      .custom-slider .MuiSlider-thumb { background-color: #ffffff; padding: 9px; border: 5px solid #1B608A; top: 0; }
      .custom-slider .MuiSlider-thumb:before { background-color: #1B608A; width: 4px; height: 4px; padding: 2px; box-shadow: none; }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px #796BE170; }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px #796BE170; }
      
      .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
      .custom-bar .MuiLinearProgress-bar { background-color: #1B608A; top: -1px; }

      // .css-eglki6-MuiLinearProgress-root { background: #d8d8d8; }
      // .css-5xe99f-MuiLinearProgress-bar1 { background: $color1; }
      // .css-eg0mwd-MuiSlider-thumb { background: $color1; }
      // .css-eg0mwd-MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
      // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px #796BE170; }
      // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px #796BE170; }
    }
    .likert-scale-container { 
      & { width: 100%; padding: 50px 0; margin-bottom: 100px; }
      .likert-scale { 
        & { width: 100%; height: 28px; border-radius: 50px; position: relative; cursor: pointer; display: flex; justify-content: space-between; align-items: center; -webkit-tap-highlight-color: transparent; }
        .scale-section { 
          & { width: calc(100% / 5); height: 100%; background-color: #D8D8D8; position: relative;  border: 2px solid white; border-top: 0; border-bottom: 0; }
          &:nth-of-type(1) { 
            &  { border-top-left-radius: 50px; border-bottom-left-radius: 50px; border-left: 0; }
            .section-title { bottom: -67px;}
            .active { color: #84161D;}
          }
          &:nth-of-type(2) .active { color: #C65B5B; }
          &:nth-of-type(3) .active { color: #EBBD79; }
          &:nth-of-type(4) .active { color: #BDCDA6; }
          &:nth-of-type(5) { 
            & { border-top-right-radius: 50px; border-bottom-right-radius: 50px; border-right: 0; }
          .section-title { bottom: -67px;}
          .active { color: #87976E;}
          }
          .section-title { padding: 11px 0; position: absolute; bottom: -45px; width: 100%; text-align: center; font-size: 18px; font-weight: 300; color: #494949; letter-spacing: 1px; width: 100px; left: 50%; transform: translateX(-50%); font-family: $helvetica; }
          .active { font-weight: 700; }
        }
        .active { background-color: transparent;}
        // .checkpoints{ &{width: 100%; height: 100%; position: absolute; top: 0; left: 0; display: flex; justify-content: space-between; align-items: center; z-index: -8;}
        //   .point{height: 100%; width: 5px; background-color: #ffffff;}
        //   .transparent{background-color: transparent; width: 0.5px;}
        // }
        .colored-bar {
          & { background: rgb(132,22,29); background: linear-gradient(90deg, rgba(132,22,29,1) 0%, rgba(132,22,29,1) 20%, rgba(198,91,91,1) 20%, rgba(198,91,91,1) 40%, rgba(235,189,121,1) 40%, rgba(235,189,121,1) 60%, rgba(189,205,166,1) 60%, rgba(189,205,166,1) 80%, rgba(135,151,110,1) 80%, rgba(135,151,110,1) 100%); display: flex; justify-content: flex-end; align-items: center; width: 100%; height: 100%; overflow: hidden; position: absolute; top: 0; left: 0; transition: all 0.5s ease-in-out; z-index: -9; border-radius: 50px; }
        }
      }
    }
    .slider-none-adjust { height: 35px; position: absolute; top: 2px; }
  }
    .step-buttons {
      & { display: flex; justify-content: space-between; margin:30px; }
      button { padding: 12px 25px; font-family: $primaryfont; font-size: 18px; letter-spacing: 0.762px;background-color:transparent; }
      .next-btn { background-color: none; color: #747474; font-weight: 800; }
      .Mui-disabled { opacity: 0.5; }
      .prev-btn { margin-left: -30px; }
    }
    .checkbox-container{
    & { display:flex; align-items:center; justify-content: center; gap: 10px; }
    input{height:20px;}
    p{color: #202020;  font-family:$fontLato; font-size: 16px;  font-weight: 400;line-height: normal;padding:0px;margin:0px; margin-bottom: 3px !important;}
  }
}
.assessment.loading { background-color: white !important; width: 96% !important; max-width: 1110px !important; height: 607px !important; margin-bottom: 0 !important;}

@media only screen and (max-width: 830px) {

  .assessment .steps {
    .progress-percent-text { display: none; }
    .question p { font-size: 23px; margin: 60px auto; }
    .progress-bar { 
      // & { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1rem; }
      & { display: flex; justify-content: center; }
      .bar-section { width: 90% !important; }
    }
    .current-section { margin: 10px auto 0; }
    .progress-indicator { margin: 50px auto; }
    .question { align-items: flex-start; }
    .slider-container .slider-labels { & { font-size: 16px; }
      .label {
        & { max-width: 10ch; }
        p { line-height: 21px; letter-spacing: normal; }
      }
    }
    .radio-container .slider-labels { 
      & { font-size: 16px; }
      // .label { max-width: 10ch; }
    }
    .likert-scale-container .likert-scale .scale-section .section-title { font-size: 14px; }
  }
  .assessment .steps .progress-bar .bar-section .section-title { font-size: 16px; }
}

@media only screen and (max-width: 650px) {

  .assessment .steps, main .assessment-additional .steps { width: 90%; padding: 0 5%; margin: 0 auto 30px; }
  .assessment .mobile-spacer { display: none; }
  .assessment .steps .progress-bar {
    .bar-section .progress-percentage { font-size: 1rem; margin: 1rem 0 0.5rem 0.75rem; }
    .sections .section-name { font-size: 15px; }
  }

}

@media only screen and (max-width: 550px) {

  .assessment { margin-top: 15px; min-height: 440px; }

  main .assessment .steps {
    .question { min-height: 150px !important; font-size: 18px; line-height: 24px; max-width: 100%; display: flex; justify-content: center; align-items: center; padding-bottom: 0; }
    .progress-bar .bar-section .section-title { font-size: 12px;  }
    .slider-container { margin: 52px auto 66px; }
    .radio-container { margin: 20px auto 42px; min-height: auto; }
    .slider-container .slider-labels .label { width: 14%; }
    .progress-indicator { margin: 15px auto 5px; margin-top: 0; color: #343333; text-align: center;  font-family: $primaryfont; font-size: 12px; font-style: normal; line-height: 15px; text-transform: uppercase; }
    .question p { color: #343333; text-align: center; font-family: $primaryfont; font-size: 18px; font-style: normal; font-weight: 400; line-height: 24px; width: 90%; margin-bottom: 0; margin-top: 20px; }
    .question-no-4 p { max-width: 26ch !important; }
    .progress-bar { grid-gap: 0; }
    .progress-bar .bar-section .bar { height: 6px; margin: 0 5px 0; }
    .progress-bar .bar-section .section-title { font-size: 12px; }
    .slider-container .slider-labels { font-size: 15px; }
    .slider-container .slider-labels .label p.desktop { display: none; }
    .slider-container .slider-labels .label p.mobile { display: block; font-size: 12px; }
    .radio-container .slider-labels { font-size: 12px; }
    .radio-container .slider-labels .label p.desktop { display: none; }
    .radio-container .slider-labels .label p.mobile { display: block; }
  }
    .step-buttons { margin: 30px auto 15px; padding-top: 15px; padding-bottom: 30px; }
    .step-buttons button {padding: 12.5px 2px; }
    .assessment .steps .likert-scale-container .likert-scale{height: 20px;}
    
    .assessment .steps .likert-scale-container .likert-scale .scale-section .section-title { font-size: 12px; width: 75px; bottom: -31px; }
    .assessment .steps .likert-scale-container .likert-scale .scale-section:nth-of-type(5) .section-title { bottom: -45px; }
    .assessment .steps .likert-scale-container .likert-scale .scale-section:nth-of-type(1) .section-title { bottom: -45px; }

  // .assessment .steps .slider-container .custom-slider.MuiSlider-root { width: calc(100% + 25px); margin-left: -6px; }
  .assessment .steps .slider-container .slider-labels .label p { width: 145%; margin-left: -75%; letter-spacing: 0px; line-height: normal; }

  .assessment .steps .progress-bar .bar-section .section-title { font-size: 14px; margin-bottom: 31px; }

}


