
.invite {
  & { min-height: 380px; width: 97%; max-width: 956px; padding-bottom: 30px; background: #fff; margin-top: 50px; }
  h1 {font-weight: 700; line-height: 43px; text-align: center; margin: 0 auto 18px; font-family: $primaryfont; font-size: 36px; color: #27282A; padding: 48px 15px 0; }
  p { text-align: center; margin: 5px auto 50px; padding: 0 10px; font-size: 21px; font-weight: 400; line-height:25px; color: #202020; max-width: 54ch; }
  p:nth-child(3) { margin-bottom: 35px; }
  .form {
    & { position: relative; display: flex; justify-content: space-around; flex-wrap: wrap; margin: 5px auto; width: 60%; }
    .invite-number { position: absolute; left: -18px; top: 50px; font-weight: bold; color: #C2C2C2; }
    .form-detail {
      & { flex: 0 1 46%; display: block; font-weight: 700; }
      p { padding: 0; margin-bottom: 5px; color: #000; font-family: $primaryfont; font-size: 16px; font-style: normal; font-weight: 700; line-height: normal; text-align: left; }
      .input-title { margin: 10px 0; }
      .MuiInput-root { font-family: $primaryfont !important; font-size: 18px; font-weight: 400; }
      .invite-field { border-radius: 3px; font-family: $primaryfont !important; background-color: #F4F4F4;}
      .invite-field .MuiInput-root { border: 1px solid transparent; border-radius: 3px; }
      .invite-field .MuiInput-root.Mui-focused { border: 1px solid #435B63; }
      .invite-field::placeholder { font-family: $primaryfont !important; font-size: 18px !important; color: #868686; font-weight: 300; }
      .invite-field .MuiInput-input { font-weight: 600; font-size: 21px; color: #343333; line-height: 26px; padding: 7.5px 10px; border-radius: 3px; -webkit-box-shadow: 0 0 0px 1000px #F4F4F4 inset; }
      .invite-field input::placeholder { font-weight: 300; opacity: 1; color: #868686; }
      .invite-field input:focus::placeholder { color: transparent; }
      .error-text { position: absolute; bottom: -20px; color: red; font-size: 10px; font-style: normal; font-weight: 400; line-height: normal; text-transform: capitalize; }
    }      
    .delete-btn {
      & { position: absolute; right: -40px; top: 48px;}
      .delete-icon { color: #D5D5D5; font-size: 30px; cursor: pointer; }
    }
    hr { display: none; }
    
  }

  .invite-another-person {
    & { margin: 0 auto; width: 55%; position: relative; height: 5px; }
    .invite-another {
      & { display: flex; align-items: center; justify-content: center; width: 60px; background-color: #000; position: absolute; top: -60px; cursor: pointer; right: -170px; border-radius: 5px; text-align: center; padding: 4px 6px; color: white; width: 74px; height: 32px; }
      &:hover { background-color: #F08D6E; }
      .add-btn { font-size: 18px; padding: 5px 5px; color: white; font-weight: 700; font-family: $primaryfont; text-transform: uppercase; line-height: 22px; }
      .invite-another-icon { vertical-align: sub; font-size: 24px; }
    }
  }
  .invite-notification-settings { & { width: 100%; text-align: center; margin: 10px; }
    .MuiFormGroup-root { display: block; }
    .notification-checkbox .MuiFormControlLabel-label { font-family: $primaryfont; font-size: 18px; }
  }
  .buttons-container { & { display: flex; justify-content: space-evenly; align-items: center; margin: 15px auto; }
    .add-btn { background-color: #000; border-radius: 5px; text-align: center; padding: 4px 6px; color: white; font-size: 18px; padding: 5px 5px; color: white; font-weight: 700; font-family: $primaryfont; text-transform: uppercase; line-height: 22px; }
  }

  .cta-btn {
    & { display: block; margin: 20px auto 50px; text-decoration: none; max-width: 200px; background-color: #7CCEE7; color: #fff; line-height: 25px; color: #FFF; text-align: center; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 400; line-height: normal; letter-spacing: 1px; text-transform: uppercase; border-radius: 5px; margin-bottom: 0; padding: 12px 15px; width: 100%; }
    &:hover { background-color: #000; transition: 0.3s; color: #fff; }
    .button-progress { margin: 4px 0 -2px; }
  }
}




@media only screen and (max-width: 550px) {
  
  .invite { & { width: 95%; border-radius: 0px; }
    h1 { font-size: 24px; line-height: 28px; font-family: $primaryfont; color: #343333; text-align: center; font-style: normal; font-weight: 700; padding: 15px 40px; margin: 0px auto; max-width: 20ch; }
    .details-container { padding: 0px;}
    h1 br { content: ""; }
    h1 br:after { content: " "; }
    p { padding: 0px 15px 45px 15px; color: #343333;text-align: center; font-size: 16px; font-style: normal; line-height: 20px; height: 40px; margin: 0px;}
    p br:after { content: " "; }

    .form { & { width: 75%; justify-content: space-between; }
      .form-detail { & { flex: 0 1 100%; }
        .invite-field { width: 100%; height: 45px; }
        p { font-size: 14px; font-weight: 400; height: 17px; }
        .error-text { bottom: auto; }
        .invite-field .MuiInput-input { color: #868686; font-size: 18px; font-weight: 300; }
        .input-title { margin: 0px auto 10px; }
      }
      .invite-number { left: -28px; top: 0px; font-size: 18px; font-weight: 400; display: flex; align-items: center; justify-content: center; }
      .delete-btn { right: auto; left: -38px; top: 35px; }
      hr { width: 318px;height:0px; color:#E4E4E4; display: block; }
    }
    
    .invite-another-person .invite-another {
      & { top: 30px; height: 32px; width: 72px; right: -26px; left: 0; margin-left: -18%; }
      .add-btn { font-size: 18px; }
    }
    .buttons-container .add-btn { background-color: #000; color: #fff; border-radius: 0px; font-weight: 400; height: 32px; width: 74px; }
    .cta-btn { width: 185px; height:50px; padding:12px 24px 13px 22px ; color:#FFF; text-align: center; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 1px; text-transform: uppercase; background-color: #7CCEE7; }
    .invite-notification-settings { 
      & { width: 80%; }
      .notification-checkbox { align-self: center; }
    }
    .buttons-container{ width: 95%; }
  }

}



@media only screen and (max-width: 830px) {

  // .invite .form { width: 75%; }
  .invite .invite-another-person .invite-another { top: 40px; top: 85px; height: 32px; width: 72px; right: -26px; left: 0; margin-left: 0%; }
  .invite .cta-btn { margin-right: 21%; margin: 0; }

  .invite-notification-settings {
    .notification-checkbox .MuiFormControlLabel-label { text-align: left; line-height: 1; }
  }

}

