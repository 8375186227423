.banner-outer-container { 
  & { height: 0; width: 95%; }
}

.banner-container {
  & {  padding: 60px; height: 125px; width: 210px; border-radius: 100%;  position: absolute;  left: -50%;  right: -50%;  top: -155px;  display: flex;  align-items: center;  justify-content: center;  margin: 0 auto; }
  .banner-inner-logo {
    & { width: 100%; }
  }
}

.paragraph-container {
  & { text-align: center; max-width: 1125px; font-family: "Lato", sans-serif; background-color: #F6F6F6; border-radius: 8px; padding: 1rem 0; margin: 40px auto; }
  .para-heading {
    & { font-size: 24px; font-weight: 700; color: #84161D; }
  }
  .paragraphs {
    & { display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; font-size: 24px; font-weight: 300; }
      p { 
        & { width: 90%; }
      }
    }
}


@media only screen and (max-width: 600px) {
  main .results .results-details-container .parish-support { width: unset; }
  main .results .results-details-container .parish-support .cards { flex-direction: column; }
  .banner-container { margin: 0 auto; }
}