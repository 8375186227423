  
.firstform-container {
  & { margin:50px auto;  border-radius: 10px; box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.0945336); width:95%; max-width:1094px; min-height:630px; background-color: #FFFFFF; }
  .error-text { color: #EE2B27; font-weight: 400; font-size: 13px;padding: 0px 3px;margin-top: 5px; max-width: 100%; text-align: left; font-family: $primaryfont; }
  .firstform {
    & { padding-top: 47px; margin: 1px auto; text-align: center; }
    h1 { font-family: $helvetica; font-weight:700; font-size: 24px; line-height: 34px; color: #84161D; text-align:center; width: 90%; margin: 16px auto; }
  }
  .form {
    & { display:flex;gap:10px; flex-direction: column; }
    .form-detail { & { margin-top: 15px; }
      .input-title { text-align: start; font-size: 16px;font-weight: 700; line-height: 24px; color: #343333; margin-bottom: 2px;margin-top:4px; }
      .input-desc{ margin-bottom: 6px; color: #03030380; font-size: 16px;font-weight: 300; line-height: 19.2px; margin-top: 0;}
      .select-container{position: relative; margin-top: 10px;}
      .form-details-text {width:361px;height: 45px;font-family: $helvetica; font-weight: 300; font-size: 18px; line-height: 21.6px;}
      .form-details-text .MuiOutlinedInput-input { font-family: $helvetica; font-weight: 400; font-size: 18px; color: #343333; text-align: left; line-height: 24px; }
      .form-details-text .Mui-focused fieldset{ border: 2px solid #EE2B27; }
      .Mui-focused .MuiOutlinedInput-notchedOutline { border: 1px solid #E6E6E6; }
      .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {border-radius: 3px; border: 1px solid #E6E6E6;}
      .MuiMenuItem-root{text-align: left;}
      
      input::placeholder { font-weight: 400; color:rgba(52, 51, 51, 0.50); opacity: 1 !important; } 
    }
    .form-detail {
      & { position: relative; }
      .select-placeholder { font-weight: 400; color:rgba(52, 51, 51, 0.50); opacity: 1 !important; font-family: $helvetica; font-size: 18px; line-height: 22px; }
    }
    .weekend-attendance{
      .slider-container{ &{display: flex; justify-content: center; align-items: center; margin-top: 35px; gap: 10px; }
        .side-label-left{color: #34333380; font-weight: 400; font-size: 14px; line-height: 17px; font-family: $helvetica; margin: 2px;}
        .side-label-right{color: #34333380; font-weight: 400; font-size: 14px; line-height: 17px; font-family: $helvetica; margin: 2px;}
        .MuiSlider-root{ &{color: #84161D; width: 100%; flex-shrink: 1;} 
          .MuiSlider-rail{ background-color: #0000001A;}
          .MuiSlider-thumb { & {background-image: url("../../Assets/Images/slider.svg"); background-repeat: no-repeat;border-radius: 0; width: 15px; height: 23px; box-shadow: none; background-color: #ffffff;}
            &::before{box-shadow: none;}
            .MuiSlider-valueLabel{background-color: transparent; color: #84161D; font-size: 22px; line-height: 27px; font-weight: 700; top: 0;}
          }
        }
      }
    }
    
    // .attendance{ padding-top: 5px; max-width: 216px;}
    // .attendance .MuiOutlinedInput-input::placeholder { font-family: $helvetica; font-weight: 300; font-size: 18px; color: rgba(52, 51, 51, 0.5); }
    // .zip{ padding-top: 10px; margin-top: 15px;}
    // .zip .MuiOutlinedInput-input::placeholder { font-family: $helvetica; font-weight: 300; font-size: 18px; color: rgba(52, 51, 51, 0.5); }
  }
     
  .content-section {
    & { margin: 1px auto; }
    .input-title { text-align: start; font-size: 16px;font-weight: 700; line-height: 19.2px; color: #343333; margin-bottom: 10px; margin-top:30px; }
    .locationtype {
      & { display: flex;justify-content: flex-start;align-items: center;gap: 15px; }
      .location { color: #030303;  font-family:$helvetica; font-size: 18px; font-weight: 400; line-height: 30px;border-radius: 5px; border: 1px solid #B1B1B1; max-width:130px;min-height: 41px; position: relative;align-items: center; display: flex; justify-content: center; overflow: hidden; padding:0 15px; width: auto; white-space:nowrap; user-select: none; cursor: pointer; }
    }
    .section{ color: #030303; font-family:$helvetica; font-size: 16px; font-weight:300; line-height: 19px}
    .complete-icon { width: 10px; height:15px; display: flex; align-items: center; justify-content: center; color: #97A97C; position: absolute; top: -3px; right:-2px; }
    p { max-width: 732px; }
  }
  .main-container{width: 85%; max-width: 732px; margin: auto;}
    .firstform-footer {
      & { width: 95%; margin: 1px auto; padding-bottom:5px; padding-top: 10px; }
      .firstform-footer-text { max-width: 42ch; text-align: center; margin: 15px auto; font-weight: 400; font-size: 18px; line-height: 24px; padding-bottom: 5px; }
    }
    .required-field { color:#EE2B27; }
    .form-second {
      & { gap:20px; }
      .Weekend-attendanc {
        .form-details-text{ width:186px; }
      }
      .socio-economic {
        .form-details-text { width: 533px; }
      }
    }
    .cta {
      & { width: 90%; max-width: 850px; margin: auto; }
    }
  }

  .secondform-container {
    & { margin: 50px auto;  border-radius: 10px; width: 95%; max-width: 720px; min-height: 540px; background-color: #FFFFFF; }
    .secondform {
      & { padding-top:30px;margin: 1px auto;text-align: center; }
      h1 { font-family: $helvetica; font-weight: 700; font-size: 24px; line-height: 34px; color: #84161D; text-align: center; padding: 0 15px; }
    }
    .form {
      & { display:flex; flex-direction: column; gap: 29px; align-items: center; user-select: none !important; }
      .form-detail {
        & { text-align: center; }
        .input-title {
          & { text-align: start; font-size: 16px;font-weight: 700; line-height: 25px; color: #343333; margin-bottom: 4px;margin-top:4px; font-family: $helvetica; text-transform: capitalize; }
          .required-field { color: #EE2B27; font-size: 16px; font-weight: 700; line-height: 25px; }
        }
        .form-details-text {width:361px;height: 45px;font-family: $helvetica; font-weight: 300; font-size: 18px; line-height: 21.6px;}
        .form-details-text .MuiOutlinedInput-input { font-family: $helvetica; font-weight: 400; font-size: 18px; color: #343333; text-align: left; line-height: 24px; }
        .form-details-text .Mui-focused fieldset{ border: 2px solid #EE2B27; }
        .Mui-focused .MuiOutlinedInput-notchedOutline { border: 1px solid #E6E6E6; }
        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {border-radius: 3px; border: 1px solid #E6E6E6;}
        .error-text { color: #EE2B27; font-weight: 400; font-size: 13px;padding: 0px 3px;margin-top: 5px; max-width: 216px; text-align: left; }
        input::placeholder { font-weight: 400; color:rgba(52, 51, 51, 0.50); opacity: 1 !important; }
        .checkbox-container { 
          & { margin: 12px 0; display: flex; align-items: center; justify-content: flex-start; }
          .custom-checkbox { appearance: none; height: 13px; width: 13px; border-radius: 2px; border: 1px solid #000; -webkit-appearance: none; position: relative }
          .custom-checkbox:checked { background-color: green; color: #fff; }
          .custom-checkbox:checked::before { content: '✔'; color: white; position: absolute; top: -3px; left: 1px; font-size: 10px; line-height: 20px; }
        }
        .input-container-parish { display: flex; justify-content: center; flex-direction: column; gap: 8px; }
        .checkbox-input {
          & { width: 361px; height: 45px; font-family: $helvetica; font-weight: 300; font-size: 18px; line-height: 21.6px; outline: none; }
        }
      }
      .form-detail {
        & { position: relative; text-align: left; }
        .select-placeholder { 
          & { font-weight: 300; color:rgba(52, 51, 51, 0.50); opacity: 1 !important; font-family: $helvetica; font-size: 18px; line-height: 22px; }
          .react-select__placeholder {
            & { font-weight: 300; color:rgba(52, 51, 51, 0.50); opacity: 1 !important; font-family: $helvetica; font-size: 18px; line-height: 22px; }
          }
         }

        .form-details-text .react-select div:nth-child(3) {  }
        .form-details-text .react-select div:nth-child(6) {  }
        .form-details-text .react-select__control--is-focused { border: 1px solid rgba(52,51, 51, 0.50); box-shadow: 0 0 0 1px rgba(52,51, 51, 0.50); } 
        .form-details-text .react-select .react-select__value-container { padding: 8px; }
        .form-details-text .react-select .react-select__placeholder { font-weight: 400; color:rgba(52, 51, 51, 0.50); opacity: 1 !important; }

        .form-details-text .react-select__clear-indicator { display: none; }
        .form-details-text .react-select__indicator-separator { display: none !important; }
        .form-details-text .react-select__dropdown-indicator{ color: #21165A; }
        .form-details-text .react-select__single-value { font-weight: 400; }
        .form-details-text.react-select { & { padding: 0; font-size: 18px; font-family: $primaryfont; font-weight: 600; margin-top: 10px; }
          // div:first-child { padding-top: 6.25px; padding-bottom: 6.25px; }
        }
      }
    }
    .cta {
      & { display: flex; justify-content: center; padding: 50px 0; margin: 0px auto; gap: 80px; }
      img { cursor: pointer; }
    }

  }
  
  .cta {
    & { display: flex; align-items: center; gap:330px; padding-top:40px; }
    .cta-btn {
      & { text-decoration: none; width:126px; height: 50px; background:#84161D; color: #fff; line-height: 25px; font-family: $helvetica; border-radius: 5px;font-weight: 700; font-size: 21px; padding: 6px 0; text-align: center;border-radius:25px; text-transform: capitalize; }
      &:hover { background: #97A97C; transition: 0.3s; color: #fff; }
    }
    .cta-btn.Mui-disabled { color: #fff;  }

  }

.first-form-loading { width: 100%; min-height: 540px; display: flex; justify-content: center; align-items: center; }

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root { justify-content: flex-start !important; }



@media only screen and (max-width: 550px) {

  
  .firstform-container { & { min-height: 525px; }
    .firstform { & { padding-top: 20px; margin: 1px auto; }
      h1 { font-weight:700; font-size: 21px; line-height: 38px;padding: 5px; }
    }
    // .firstform-footer { & { max-width: 40ch; text-align: center; padding: 5px 5px 10px 5px; }
    //   .firstform-footer-text { font-weight: 400; font-size: 15px; line-height: 24px; margin-top: 40px; }
    // }
}
  .cta {justify-content: space-between; padding:40px 0px; gap: 0px;}
.firstform-container .content-section p { max-width: 300px;}
.secondform-container .form .form-detail .form-details-text { max-width: 295px; }
.firstform-container .form .weekend-attendance .slider-container { position: relative; }
.firstform-container .form .weekend-attendance .slider-container .side-label-left { position: absolute; left: 0; bottom: -15px; font-size: 16px; }
.firstform-container .form .weekend-attendance .slider-container .side-label-right { position: absolute; right: 0; bottom: -15px; font-size: 16px; }
.firstform-container .form .weekend-attendance .slider-container .MuiSlider-root .MuiSlider-thumb .MuiSlider-valueLabel { font-size: 16px; }

}

@media only screen and (max-width:800px) {
  .firstform-container {
    .main-container { margin: 0 auto; justify-content: center; width: 94%; }
    .form .form-details-text { width: 100% !important; }
    .locationtype { gap: 5px; }
    .location { font-size: 17px; }
    .select-container { 
      .select-placeholder { font-size: 16px !important; }
    }
  }
  .secondform-container .cta { margin: 0 auto; justify-content: space-around; }
  // .firstform-container .form { flex-direction: column; gap: 30px; }

}

