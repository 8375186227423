.cover{
  & { background-color: #fff; width: 100%; }

  .dashboard-insigts{
    & { width: 100%; background-color: #F5F5F5; height: calc(100vh - 90px); overflow-y: scroll; border-top-left-radius: 1rem; } 
    .tabs{
      & { max-width: 100%; width: 94%; display: flex; flex-direction: row; align-items: start; gap: 0.3rem; margin-left: 3%; margin-top: 2rem; }
      .tab { 
        & { background-color: #fff; border-radius: 10px; cursor: pointer; flex-grow: 0; flex-wrap: nowrap; }
        p { color: #5F5F5F; font-size: 17px; margin: 8px 10px; font-weight: 300; font-family: $helvetica; }
      }
      .tab.active0 { 
        & { border-bottom-left-radius: 0; border-bottom-right-radius: 0; background-color: #fff; }
        p { color: #97A97C; font-weight: 700; border-bottom: 1px solid #97A97C; margin-bottom: 20px; }
      }  
      .tab.active1 { 
        & { border-bottom-left-radius: 0; border-bottom-right-radius: 0; background-color: #fff; }
        p { color: #84161D; font-weight: 700; border-bottom: 1px solid #84161D; margin-bottom: 20px; }
      }  
      .tab.active2 { 
        & { border-bottom-left-radius: 0; border-bottom-right-radius: 0;  background-color: #fff; }
        p { color: #44546A; font-weight: 700; border-bottom: 1px solid #44546A; margin-bottom: 20px; }
      }  
    }
    .box { 
      & { max-width: 100%; width: 94%; margin: auto; border-radius: 15px; background-color: #fff; padding-bottom: 1rem; }
      .leppa-container { 
        .lpp { font-weight: 300; align-self: flex-start; margin-left: 2%; font-size: 18px; font-family: $helvetica; color: #3B3939;  }
        // .head .row .h-cell { padding: 0px 8px; }
        .lepa-container { 
          & { display: flex; gap: 2rem;}
          .lp-container { 
            & { display: flex; align-items: center; justify-content: center;   }
            p { font-family: $helvetica; font-size: 21px; font-weight: 300; color: #3B3939;  display: inline-flex; margin: 0; }
            img { object-fit: contain; width: 33px; margin-right: 0.4rem; }
          }
        }
      }
    }

  }
  .header-container { 
    & { margin: 0 auto; max-width: 90%; display: flex; align-items: center; justify-content: center; box-sizing: border-box; height: 88px; margin-top: 2rem;   }
    .user-details { 
      & { display: flex; align-items: center; gap: 1rem;  border-radius: 10px;  border: 1px solid #84161D; padding: 10px; background: #fff; }
      .flag { 
        & {  border-radius: 50%; width: 48px; height: 48px; margin: auto; display: flex; align-items: center; justify-content: center; } 
        img { width: 28px; height: 100%; object-fit: contain; }
      }
      .names {
         h3 { margin: 0; padding: 0; font-size: 14px; font-weight: 700; font-family: $helvetica; color: #555555; line-height: 35px; }
         p { margin: 0; padding: 0;  font-size: 14px; font-weight: 300; font-family: $helvetica; color: #555555; }
       }
      .dropdown { 
        & { width: 28px; height: 28px; border-radius: 50%; display: flex; justify-content: center; align-items: center; cursor: pointer; }
        img { object-fit: contain; height: 100%; width: 16px; }
        }
     }  
  }
}

@media only screen and (max-width: 600px) {
  .cover {
    .dashboard-insigts { 
      & { border-radius: 0; }
      .tabs {
        .tab {
          p { font-size: 12px; }
        }
      }
      .box {
        .leppa-container { 
          & { flex-direction: column; padding: 11px; gap: 0; }
          .lepa-container  { gap: 0rem; justify-self: end; margin-left: auto; }
          .lp-container { 
            p { font-size: 14px !important; font-weight: 300; line-height: 15px; text-wrap: wrap; width: 50%; text-align: center; }
           }
        }
      }
     }
  }

  .cover .dashboard-insigts .box .leppa-container .lepa-container .lp-container { right: 10%; }
  .cover .dashboard-insigts .tabs .tab { background-color: unset; }
}