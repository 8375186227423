.sidebar-cover {
  & { width: clamp(14rem, 18%, 20%); background-color: #fff; display: flex; flex-direction: column; padding-right: 1rem; height: calc(100vh - 90px); overflow-y: auto; }
 .greeting { margin-left: .5rem; font-size: 28px; line-height: 43px; font-weight: 300; font-family: Helvetica Arial, sans-serif; color: #555555; } 
 .primary-tabs { 
  .tab { 
    & { background-color: #44546A; display: flex; align-items: center; padding-left: 2rem; gap: 0.8rem; padding-left: 20px; cursor: pointer; } 
    img { object-fit: cover; height: 100%; width: 18px; }
    p { color: #FFFFFF; font-weight: 300; font-size: 19px; }
  }
  .tab:first-child { border-top-right-radius: 1rem; }
  .tab:last-child { border-bottom-right-radius: 1rem; }
  .active { 
    & {background-color: #2C3C51; }
    p { font-weight: 500; } 
  }

  }
  .secondry-tabs{
    & { position: relative; margin-top: 3rem; border: 3px solid #dadde1; border-top-right-radius: 1rem; border-bottom-right-radius: 1rem; border-left: none; padding-top: 2rem; }
    .res { position: absolute; padding: 0.5rem; background-color: #fff; font-weight: 600; left: 2rem; top: -35px; }
    .tab { 
      & { display: flex; align-items: center; gap: 0.8rem; padding-left: 2rem; }
      img { object-fit: cover; height: 100%; width: 36px; }
     }
  }
 .third-tabs {
  & { padding-top: 1rem; }
  .tab { 
    & { display: flex; align-items: center; padding-left: 2rem; gap: 0.8rem; cursor: pointer; } 
    img { object-fit: cover; width: 32px; height: 100%; }
    .circle { border-radius: 28px; width: 30px; height: 30px; background-color: #ffffff;
      img { width: 16px; height: 16px; padding: 7px; }
    }
  }
 } 
 .logo {
  & { object-fit: cover; width: 11rem; margin: 1.5rem auto 0.5rem; padding-top: 1rem; align-items: flex-end; margin-top: auto; }
 }
}
.sidebar-cover::-webkit-scrollbar {width: 8px;} 
.sidebar-cover::-webkit-scrollbar-track {-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.089);-webkit-border-radius: 12px;border-radius: 12px;}
.sidebar-cover::-webkit-scrollbar-thumb {-webkit-border-radius: 12px;border-radius: 12px;background: rgba(0,0,0,0.2);-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.089);}
.sidebar-cover::-webkit-scrollbar-thumb:window-inactive {background: rgba(0, 0, 0, 0.089);}

@media only screen and (max-width: 600px) {
  .sidebar-cover {
    & { width: 100%; height: auto; padding: 0; overflow-y: hidden; background-color: #fff; border-top-right-radius: 1ren; }
    .manu { 
      & { display: flex; align-items: center; background-color: #F6F6F6; padding-top: 0.5rem; padding-bottom: 0.5rem; }
      .manu-icon { object-fit: contain; width: 32px; margin-left: 1rem; cursor: pointer; }
      p { font-size: 18px; line-height: 35px; font-weight: 300; margin: auto; }
     }
    .sidebar { 
      & { border-top-right-radius: 1rem; background-color: #fff; width: 14rem; z-index: 10; position: absolute; transform: translateX(-110%); transition: transform 0.3s ease-in-out; box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); }
      .primary-tabs {
        .close-manu { align-self: self-start; position: relative; top: -0.5rem; right: -0.5rem; padding: 1rem; }
        .tab:first-child{ padding-top: 0.3rem;  }
      }
    }
    .slide-show { transform: translateX(0); }
    .slide-hide{ transform: translateX(-100%); box-shadow: none; }

  }
}