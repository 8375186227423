.progress-milestone { 
  & { background-color: #FFFFFF; border-radius: 10px; width: 96%; margin: auto; min-height: 607px; margin-bottom: 0; }
  .heading {
    & { font-family: $helvetica; font-size: 24px; font-weight: 700; max-width: 751px; margin: -20px auto 0; text-align: center; color:  #84161D; line-height: 35px; }
  }
  .section-info { 
    & { width: fit-content; margin: 40px auto; text-align: center; }
    .image-container { 
      & { width: 120px; height: 120px; background-color: #8DAFC4; border-radius: 100%; display: flex; justify-content: center; align-items: center; margin: auto; }
      img{
        & { height: 50%; }
      }
    }
    .section-name{
      & { margin: 20px auto 0px; font-weight: 700; font-size: 21px; text-transform: uppercase; }
    }
    .questions{ 
      & { display: flex; justify-content: center; align-items: center; }
      p { color: #343333; font-size: 18px; font-family: $helvetica; margin: 10px 5px; }
    }
  }
  .section-description {
    & { font-family: $helvetica; font-size: 24px; font-weight: 300; line-height: 35.28px; text-align: center; max-width: 50ch; width: 90%; margin: 50px auto 30px; color: #343333; }
  }
  .pause-screen-img {
    & { max-height: 120px; max-width: 120px; display: flex; align-items: center; justify-content: center; margin: 0 auto; border-radius: 50%; }
  }
  .sub-heading{
    & { width: 90%; margin: 10px auto; text-align: center; color: #84161D; font-size: 18px; font-family: $helvetica; font-weight: 300; line-height: 25px; }
    .line-one { font-family: $helvetica; font-size: 18px; font-weight: 700; line-height: 25px; color: #44546A; }
    .line-two { font-family: $helvetica; font-size: 18px; font-weight: 300; line-height: 25px; color: #44546A; }
  }
  .action-btn {
    & { margin: 25px auto 40px; width: fit-content; } 
    button {
      & {background-color: #84161D; color: #FFFFFF; text-align: center; font-family: $helvetica; font-size: 21px; font-weight: 700; letter-spacing: 1px; padding: 10px 50px; border-radius: 50px; cursor: pointer; border: none; line-height: 31px; } 
      &:hover { background-color: #97A97C; }
    }
  }
}
.milestone-3 { 
    & { width: 96%; }
    .section-info {
      & { width: fit-content; margin: 40px auto 0px; text-align: center; }
      .image-container {
        & { width: 120px; height: 120px; background-color: #97A97C; border-radius: 100%; display: flex; justify-content: center; align-items: center; margin: auto; }
        img{ height: 50%;}
      }
      .section-name {
        & { margin: 40px auto 0px; font-weight: 700; font-size: 24px; text-transform: capitalize; }
      }
    }
    .email-request { color: #000000; text-align: center; font-family: $helvetica; font-size: 24px; font-weight: 300; line-height: 35px; max-width: 900px; margin: 10px auto 20px; }
    .information { color: #343333; text-align: center; font-family: $helvetica; font-size: 21px; font-weight: 300; max-width: 900px; margin: 20px auto; }
    .input { & { max-width: 320px; width: fit-content; margin: 40px auto 20px; }
        p { & { margin: 0; color: #3B3A3A; font-family: "Inter", sans-serif; font-size: 16px; font-weight: 700; line-height: normal; }
            span { color: #CB6015; }
        }
        input { & { border-radius: 5px; background: #F3F3F3; padding: 10px; font-family: "Inter", sans-serif; font-size: 18px; font-weight: 400; border: none; margin: 5px 0; }
            &:focus { outline: none;}
            &::placeholder { font-weight: 300;font-size: 18px; color: #868686; line-height: 26px; }
        }
    }
    .action-btn { margin: 20px auto 40px; }
    .extra-margin-btn { margin: 60px auto 40px; }
    .content{ max-width:527px; margin: 0 auto 30px; font-size:14px; font-weight:300; text-align: center; line-height:20px; color:#343333; font-family:$helvetica; padding-bottom: 24px; }
}
 .footer-text {
    & { color: #FFF; text-align: center; margin: auto; font-family: $helvetica; font-size: 16px; font-weight: 300; line-height: 34px; max-width: 95%; }
    a { text-decoration: underline;  color: #FFF; }
  }

 .step-buttons {& { display: flex; justify-content: space-between; margin:40px 50px;padding:0px; }
    button { padding: 12px 25px; font-family: $primaryfont; font-size: 18px; letter-spacing: 0.762px;background-color:transparent; }
    .next-btn { background-color: none; color: #747474; font-weight: 800; }
    .prev-btn { margin-left: -30px; }
 }

@media only screen and (max-width: 1000px) {
    .progress-milestone {
        // .section-description, .email-request, .information { max-width: 800px; }
    }
}
@media only screen and (max-width: 830px) {
    .progress-milestone {
        .heading { font-size: 21px; margin: 0 auto; max-width: 90%; }
        .section-info {
            .image-container { width: 90px; height: 90px; }
            .section-name{ font-size: 18px; }
            .questions{
                p{ font-size: 16px; }
            }
        }
        .section-description { font-size: 21px; }
        .action-btn { & { margin: 40px auto; max-width: 95%; } 
            button { font-size: 18px; padding: 10px 40px; }
        }
    .content{width: 90%;}
    }
    .milestone-3 { 
        .section-info { & { margin: 30px auto 0; }
            .image-container { width: 90px; height: 90px; }
            .section-name { font-size: 18px; margin: 30px auto 0px; }
        }
        .email-request { font-size: 18px; max-width: 90%; }
        .information { font-size: 16px; max-width: 90%; }
        .input { & { max-width: 90%; margin: 40px auto 20px; }
            p { font-size: 14px; }
            input { font-size: 16px; }
        }
    }
    .footer-text { font-size: 18px; max-width: 90%; line-height: 30px; margin-top:0px; }

}

@media only screen and (max-width: 550px) {
    .progress-milestone {
        .heading { font-size: 18px; color: #84161D; }
        .section-info {
            .image-container { width: 80px; height: 80px; }
            .section-name{ margin: 10px auto 0px; font-size: 16px; }
            .questions{
                p{ font-size: 14px; }
            }
        }
        .section-description { font-size: 18px; line-height: 28px; }
        .action-btn { & { margin: 30px auto; max-width: 90%; } 
            button { font-size: 16px; padding: 10px 40px; }
        }
    }
    .milestone-3 { 
        .section-info { & { margin: 30px auto 0; }
            .image-container { width: 90px; height: 90px; }
            .section-name { font-size: 16px; margin: 20px auto 0px; }
        }
        .email-request { font-size: 16px; line-height: normal; margin-bottom: 10px; }
        .information { font-size: 14px; }
        .input { & { max-width: 90%; margin: 40px auto 20px; overflow: hidden; }
            p { font-size: 14px; }
            input { font-size: 16px; }
        }
    }
    .footer-text { font-size: 16px; line-height: 25px; }

}