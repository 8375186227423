.matrix{
  & { font-family:$helvetica;  width: 96%; margin: auto; margin-bottom: 1rem; border-radius: 12px; padding: 0; border-collapse: collapse; }
  .head { 
     .row { display: flex; } 
      .h-cell { font-size: 21px; line-height: 30px; color: #fff; text-align: start; font-weight: 700; padding: 8px 8px 8px 16px; margin: 0; display: flex; align-items: center; font-family: $helvetica; }
      .alignment-text{ font-size: 16px; font-weight: 300; text-wrap: nowrap; margin: 0; width: 100%; text-align: right; }
      .thead-img {  background-color: #fff; border-radius: 100%; padding: 4px;  object-fit: contain; height: 24px; margin-right: 0.8rem; }
      .collapse-img { object-fit: contain; height: 18px; text-align: center; cursor: pointer; }
      .h-cell:first-child{ border-top-left-radius: 10px; }
      .h-cell:last-child{ border-top-right-radius: 10px; }
      .superscript{ font-family: $helvetica; font-size: 19px; font-weight: 700; vertical-align: top; position: relative; top: -3px; left: 1px; }
  }
  .body { 
    .row { display: flex; min-height: 60px; }
    .b-cell { display: flex; align-items: center; color: #343333; font-size: 18px; line-height: 20px; font-weight: 300; text-align: start; padding: 4px 8px 4px 16px; font-family: $helvetica; }
    .b-cell:not(:first-child){ justify-content: center;}
    .row:nth-child(even){ background-color: #F0F0F0; }
    .row:last-child{ border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; }
    .mobile-sub-head { 
      & {  display: flex; flex-direction: column;}
      .h-cell { font-family: $helvetica;  font-size: 12px; font-weight: 700; line-height: 18px; text-align: center; margin: 0px 10px;  padding: 10px; }
      .lp-container img { width: 21px !important;   }
      .lepa-container { margin-left: 0 !important; }
      .row { background: #F5F5F5;  width: 94%;  margin: 0px auto; margin-top: 10px; border-radius: 5px; justify-content: space-between; } 
    }
  }
}

.collapsed {
  .head {
    .h-cell:first-child { border-bottom-left-radius: 10px; }
    .h-cell:last-child { border-bottom-right-radius: 10px; }
  }
}

.green {
  & { border: 2px solid #97A97C; }
  .head {
    .h-cell { background-color: #97A97C; }
    .h-cell:nth-child(2) { border-left: 2px solid #D0DCBF; text-align: center; }
    .h-cell:nth-child(3){ border-left: 2px solid #D0DCBF; border-right: 2px solid #D0DCBF; text-align: center; }
  }
  .body {
    .b-cell:nth-child(2){ border-left: 2px solid #D0DCBF; text-align: center; }
    .b-cell:nth-child(3){ border-left: 2px solid #D0DCBF; border-right: 2px solid #D0DCBF; text-align: center; }
  }
}
.green1 {
  & { border: 2px solid #97A97C; }
  .head {
    .h-cell { background-color: #97A97C; }
    // .h-cell:nth-child(2) { border-left: 2px solid #D0DCBF; text-align: center; }
    // .h-cell:nth-child(3){ border-left: 2px solid #D0DCBF; border-right: 2px solid #D0DCBF; text-align: center; }
  }
  .body {
    .b-cell:nth-child(2){ border-left: 2px solid #D0DCBF; text-align: center; }
    .b-cell:nth-child(3){ border-left: 2px solid #D0DCBF; border-right: 2px solid #D0DCBF; text-align: center; }
  }
}

.red {
  & { border: 2px solid #84161D; }
  .head {
    .h-cell { background-color: #84161D; }
    // .h-cell:nth-child(2) { border-left: 2px solid #D0DCBF; text-align: center; }
    // .h-cell:nth-child(3){ border-left: 2px solid #D0DCBF; border-right: 2px solid #D0DCBF; text-align: center; }
  }
  .body {
    .b-cell:nth-child(2){ border-left: 2px solid #BBC9D2; text-align: center; }
    .b-cell:nth-child(3){ border-left: 2px solid #BBC9D2; border-right: 2px solid #BBC9D2; text-align: center; }
  }
}

.blue {
  & { border: 2px solid #44546A; }
  .head {
    .h-cell { background-color: #44546A; }
    // .h-cell:nth-child(2) { border-left: 2px solid #BBC9D2; text-align: center; }
    // .h-cell:nth-child(3){ border-left: 2px solid #BBC9D2; border-right: 2px solid #BBC9D2; text-align: center; }
  }
  .body {
    .b-cell:nth-child(2){ border-left: 2px solid #BBC9D2; text-align: center; }
    .b-cell:nth-child(3){ border-left: 2px solid #BBC9D2; border-right: 2px solid #BBC9D2; text-align: center; }
  }
}

@media only screen and (max-width: 600px) {
  .matrix {
    & { width: 90%; }
    .head {
      .h-cell { font-size: 12px; line-height: 14px; text-transform: uppercase; padding-left: 4px; padding-right: 4px; white-space: nowrap; }
      // .h-cell:nth-child(1) { width: 45% !important; }
      .h-cell:nth-child(2) { width: 25% !important; padding: 0 2px; }
      .h-cell:nth-child(3) { width: 25% !important; padding: 0 2px; }
      .h-cell:nth-child(4) { width: 5%  !important; padding: 0 11px;}
      .h-cell.collapse {  width: 11% !important;  }

    }
    .body {
      .b-cell { font-size: 12px; line-height: 20px; padding: 4px; }
      .b-cell:not(:first-child){ font-size: 15px; }
      .b-cell:nth-child(1) { width: 45% !important; }
      .b-cell:nth-child(2) { width: 25% !important; padding: 0 2px; }
      .b-cell:nth-child(3) { width: 25% !important; padding: 0 2px; }
      .b-cell:nth-child(4) { width: 5%  !important; padding: 0 4px;}
    }
  }
  .matrix .head .superscript { font-size: 10px; top: -4px; }
}


@media only screen and (max-width: 850px) {

  .matrix .head .compare .h-cell:nth-child(2) { border-left: 0px; }
  .matrix .head .compare .h-cell:nth-child(2) { width: 20px !important; }
  .matrix .head .compare .h-cell { padding: 13px 8px; font-size: 16px; font-weight: 700; }
  .dashboard-compare .box .le-pa-container .lp-container.compare { display: block; }
  .dashboard-compare .box .le-pa-container .lp-container.compare .contents { display: flex; gap: 10px; align-items: center; }
  .dashboard-compare .box .le-pa-container .lp-container.compare .completion-bar { max-width: 81px; }
  .lp-container.compare { display: block; }
  .lp-container .contents { display: flex; align-items: center; }
  .lp-container.compare .contents { gap: 10px; }
  .lp-container.compare .completion-bar { max-width: 81px; }
  .dashboard-compare .tabs { justify-content: space-between; }

}